import React from 'react';
import { useCart } from 'react-use-cart';   
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { Button, Card, Paper } from '@mui/material';
import { img_path } from '../../../config';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { Link } from 'react-router-dom';
const Cart = () => {
    const cartItem = useCart()
    const styles = {
        float_l: {
            float: 'left!impotant'
        }
    }
    const toLowerCase=  (name) => {
        if(name !== null){
           return String(name).toLowerCase(); 
        }else{
            return ''
        }
    }
    const gst = (price) => {
        return price * (8/100)
    }
    return (
        <>
            <div className='container mt-4 mb-3'>
                <h3 className='mb-3'>Cart</h3>
                <div className='row'>
               <div className='col-8'>
                 {cartItem.items.map((item)=>
                    (<Paper key={item.id} className="mb-3" style={{backgroundColor: '#f8f7f5'}} variant="outlined">
                        <div className='row p-3'>
                            <div className='col-3'>
                                <img src={`${img_path}/product/${item.image}`} className="img-fluid" style={{width: '70px'}}/>
                            </div>
                            <div className='col-6 text-left'>
                               <h6 className='text-left'>{item.product_name}</h6>
                               <p>{toLowerCase(item.description)}</p>
                               <a href='javascript:void(0)' onClick={() => cartItem.removeItem(item.id)}>Remove</a>
                            </div>
                            <div className='col-3'> 
                               <Button color="inherit" onClick={() => cartItem.updateItemQuantity(item.id, cartItem.getItem(item.id).quantity - 1)}><RemoveCircleOutlineRoundedIcon/></Button>
                                  {cartItem.getItem(item.id).quantity}   
                               <Button color="inherit" onClick={() => cartItem.updateItemQuantity(item.id, cartItem.getItem(item.id).quantity + 1)}><AddCircleOutlineRoundedIcon/></Button>   
                               <p>{cartItem.getItem(item.id).quantity} items @ {parseFloat(cartItem.getItem(item.id).price)} <br/> (Rs {parseFloat(cartItem.getItem(item.id).price * cartItem.getItem(item.id).quantity).toFixed(2)})</p>                       
                            </div>
                        </div>
                   </Paper>)                 
                 )}                 
               </div>
               <div className='col-4'>
                   <Paper elevation={4} variant="elevation" className='py-4'>
                       <h4 className='mb-2 text-danger'>{cartItem.totalItems} Items </h4>
                       <table className='w-100 px-2' style={{borderBottom: '1px solid #e9e5e5'}}>
                          <thead></thead>
                         <tbody>
                          <tr>
                            <td style={styles.float_l}>SubTotal</td>
                            <td>Rs {parseFloat(cartItem.cartTotal).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td style={styles.float_l}>GST</td>
                            <td>Rs {parseFloat(gst(cartItem.cartTotal)).toFixed(2)}</td>
                          </tr>
                          </tbody>
                          <tfoot>

                          </tfoot>
                       </table>
                       
                       <Button className='mt-3' variant="contained" color='error'>
                         <Link to="/checkout">
                            <div className='d-flex text-white'>
                                <div className='mr-5' style={{marginRight: '15px'}}>
                                   Checkout
                                </div>
                                <div className='ml-4'>
                                  Rs {parseFloat(cartItem.cartTotal + gst(cartItem.cartTotal)).toFixed(2)} 
                                </div>
                            </div>
                          </Link>   
                        </Button>
                   </Paper>
               </div>
               </div>
            </div>
           
        </>
    );
}

export default Cart;
