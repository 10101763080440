import React, { useEffect, useState } from "react";
import "./Header.css";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCart } from "react-use-cart";
import { Badge } from "@mui/material";
const Header = (props) => {
  const params = useParams()
  const [data, setData] = useState(null)
  const [mobile, setMobile] = useState(false)
  const cart = useCart()
  const handleToggle=  () => {
     if(mobile){
      setMobile(false)
     }else{
      setMobile(true)
     }    
  }
  useEffect(()=>{
    let isCart = true
    if(isCart){
      console.log(cart)
    }
    return () => {
       isCart = false
    }
  },[cart])
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light osahan-nav shadow-sm fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img alt="logo" src="/image/logo/logo.png" className="logo"/>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={mobile? 'navbar-collapse': 'collapse navbar-collapse'} id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="/menu">
                  Menu
                </Link>
              </li>
              <li className="nav-item" style={{marginRight: '36px'}}>
                <Link className="nav-link" to="/deals">
                  <i className="icofont-sale-discount"></i> Deals{" "}
                  <span className="badge badge-danger">New</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  <AccountCircleOutlinedIcon style={{fontSize: '30px'}}/>
                  <span>Sign in</span>
                </Link>
              </li>
             
              <li className="nav-item dropdown dropdown-cart">
                <Link
                  className="nav-link"
                  to="/cart"
                >
                  <Badge badgeContent={cart.totalItems} color="error">
                    <img src="/image/header/add-to-cart.png" width="35px"/>
                  </Badge>
                </Link>
                <div className="dropdown-menu dropdown-cart-top p-0 dropdown-menu-right shadow-sm border-0">
                  <div className="dropdown-cart-top-header p-4">
                    <img
                      className="img-fluid mr-3"
                      alt="osahan"
                      src="img/cart.jpg"
                    />
                    <h6 className="mb-0">Gus's World Famous Chicken</h6>
                    <p className="text-secondary mb-0">
                      310 S Front St, Memphis, USA
                    </p>
                    <small>
                      <a className="text-primary font-weight-bold" href="#">
                        View Full Menu
                      </a>
                    </small>
                  </div>
                  <div className="dropdown-cart-top-body border-top p-4">
                    <p className="mb-2">
                      <i className="icofont-ui-press text-danger food-item"></i>{" "}
                      Chicken Tikka Sub 12" (30 cm) x 1{" "}
                      <span className="float-right text-secondary">$314</span>
                    </p>
                    <p className="mb-2">
                      <i className="icofont-ui-press text-success food-item"></i>{" "}
                      Corn & Peas Salad x 1{" "}
                      <span className="float-right text-secondary">$209</span>
                    </p>
                    <p className="mb-2">
                      <i className="icofont-ui-press text-success food-item"></i>{" "}
                      Veg Seekh Sub 6" (15 cm) x 1{" "}
                      <span className="float-right text-secondary">$133</span>
                    </p>
                    <p className="mb-2">
                      <i className="icofont-ui-press text-danger food-item"></i>{" "}
                      Chicken Tikka Sub 12" (30 cm) x 1{" "}
                      <span className="float-right text-secondary">$314</span>
                    </p>
                    <p className="mb-2">
                      <i className="icofont-ui-press text-danger food-item"></i>{" "}
                      Corn & Peas Salad x 1{" "}
                      <span className="float-right text-secondary">$209</span>
                    </p>
                  </div>
                  <div className="dropdown-cart-top-footer border-top p-4">
                    <p className="mb-0 font-weight-bold text-secondary">
                      Sub Total{" "}
                      <span className="float-right text-dark">$499</span>
                    </p>
                    <small className="text-info">Extra charges may apply</small>
                  </div>
                  <div className="dropdown-cart-top-footer border-top p-2">
                  
                    <a
                      className="btn btn-success btn-block btn-lg"
                      href="checkout.html"
                    >
                      {" "}
                     
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div style={{height: '80px'}}>

      </div>
    </>
  );
};
export default Header;
