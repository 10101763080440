import React from "react";
import Slider from "../../Slider/Slider";
import Category from "../../Category/Category";
import Deals from "../../Deals/Deals";
const Home = () => {
    console.log('home component')
    return (
        <>
         <Slider/>
         <Category/>
         <Deals/>
        </>
    )
}
export default Home;