import './App.css';
import Header from './components/Header/Header';
import Home from './components/Page/Home/Home';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import ProductCategory from './components/Page/ProductCategory/ProductCategory';
import Deal from './components/Page/Deal/Deal';
import About from './components/Page/About/About';
import React, { useEffect } from 'react';
import Contact from './components/Page/Contact/Contact';
import Careers from './components/Page/Careers/Careers';
import Blog from './components/Page/Blog/Blog';
import { CartProvider } from 'react-use-cart';
import Cart from './components/Page/Cart/Cart';
import Checkout from './components/Page/Checkout/Checkout';
const ScrollToTop = () => {
  const location = useLocation()
  useEffect(()=>{
      window.scrollTo(0, 0)
  },[location])
}
function App() {
  
  return (
    <div className="App">
      <CartProvider>
       <Router>
        <ScrollToTop/>
        <Header/>
          <Routes>
             <Route exact path="/" element={<Home/>} />
             <Route exact path="/category/:id" element={<ProductCategory/>} />
             <Route exact path="/menu" element={<ProductCategory/>} />
             <Route exact path="/all-items" element={<ProductCategory/>} />
             <Route exact path="/deals" element={<Deal/>} />
             <Route exact path="/about" element={<About/>} />
             <Route exact path="/contact" element={<Contact/>} />
             <Route exact path="/career" element={<Careers/>} />
             <Route exact path="/blogs" element={<Blog/>} />
             <Route exact path="/cart" element={<Cart/>} />
             <Route exact path="/checkout" element={<Checkout/>} />
          </Routes>
          <Footer/>  
       </Router> 
       </CartProvider>
    </div>
  );
}
export default App;
