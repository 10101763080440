import React from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import TapasIcon from '@mui/icons-material/Tapas';
import GroupsIcon from '@mui/icons-material/Groups';
const About = () => {
  return (
    <>
      <section className="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
        <h1 className="text-white">WelCome To Krispy Chiken</h1>
        <h6 className="text-white-50">
          Explore top deals and offers exclusively for you!
        </h6>
      </section>
      <section className="section pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="font-weight-bold mt-0 mb-3">About Our Jurney</h4>
              <p>
                It all started with Colonel Harland Sanders creating a finger
                lickin’ good recipe more than 75 years ago. A list of secret
                herbs and spices scratched out on the back of his kitchen door,
                that continues to be followed across 145 countries, with more
                than 800,000 team members breading and freshly preparing bucket
                after bucket of our signature Hot &amp; Crispy chicken. The
                Colonel’s Secret Recipe continues to be as guarded; and our
                commitment towards Food, People and Planet remains as steadfast.
                With a new KFC restaurant opening every 6 hours, we power on to
                serve the World’s Tastiest Chicken!
              </p>
            </div>
            <div className="col-md-3">
              <div className="card offer-card border-0 shadow-sm">
                <div className="card-body">
                <CalendarMonthIcon sx={{fontSize: '60px', marginBottom: '10px'}}/>
                  <h4 className="card-subtitle mb-2 text-block">Since</h4>
                  <h6 className="card-text">2015</h6>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card offer-card border-0 shadow-sm">
                <div className="card-body">
                  <LocationCityIcon sx={{fontSize: '60px', marginBottom: '10px'}}/>
                  <h4 className="card-subtitle mb-2 text-block">States</h4>
                  <h6 className="card-text">10</h6>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card offer-card border-0 shadow-sm">
                <div className="card-body">
                  <TapasIcon sx={{fontSize: '60px', marginBottom: '10px'}}/>
                  <h4 className="card-subtitle mb-2 text-block">Resturants</h4>
                  <h6 className="card-text">1k</h6>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card offer-card border-0 shadow-sm">
                <div className="card-body">
                  <GroupsIcon sx={{fontSize: '60px', marginBottom: '10px'}}/>
                  <h4 className="card-subtitle mb-2 text-block">Team</h4>
                  <h6 className="card-text">2500+</h6>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row pt-5">
            <div className="col-md-12">
              <h4 className="font-weight-bold mt-0 mb-3">Bank Offers</h4>
            </div>
            <div className="col-md-6">
              <div className="card offer-card-horizontal border-0 shadow-sm">
                <div className="row d-flex align-items-center no-gutters">
                  <div className="col-md-4 col-4 p-4">
                    <img src="img/bank/7.png" className="card-img" alt="..." />
                  </div>
                  <div className="col-md-8 col-8">
                    <div className="card-body">
                      <h5 className="card-title">
                        Get flat $.30 cashback using Amazon Pay
                      </h5>
                      <p className="card-text">
                        Get flat $.30 cashback on orders above $.99 for 10
                        orders. No code required.
                      </p>
                      <p className="card-text">
                        <small className="text-info">Other T&Cs may apply</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card offer-card-horizontal border-0 shadow-sm">
                <div className="row d-flex align-items-center no-gutters">
                  <div className="col-md-4 col-4 p-4">
                    <img src="img/bank/8.png" className="card-img" alt="..." />
                  </div>
                  <div className="col-md-8 col-8">
                    <div className="card-body">
                      <h5 className="card-title">
                        Get flat $.30 cashback using Osahan Pay
                      </h5>
                      <p className="card-text">
                        Get flat $.30 cashback on orders above $.99 for 10
                        orders. No code required.
                      </p>
                      <p className="card-text">
                        <small className="text-info">Other T&Cs may apply</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};
export default About
