import React from "react";
import "./Deals.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Button, Card, CardActions } from "@mui/material";
const Deals = () => {
  return (
    <>
      <div className="deals">
        <div className="container">
          <h3 className="pb-3">Offers & Deals </h3>
          <div className="row">
            <div className="col-12">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                style={{
                  "--swiper-pagination-color": "#6e6e6e",
                  "--swiper-navigation-color": "#6e6e6e",
                }}
                breakpoints={{
                  640: {slidesPerView: 2}
                }}
              >
                <SwiperSlide>
                  <div className="card offer-card-horizontal border-0 shadow-sm">
                    <div className="row d-flex align-items-center no-gutters">
                      <div className="col-md-4 col-4 p-4">
                        <img
                          src="image/deals/1.jpg"
                          className="card-img"
                          alt="..."
                        />
                      </div>
                      <div className="col-md-8 col-8">
                        <div className="card-body">
                          <h5 className="card-title">
                            Get flat ₹.30 cashback using Amazon Pay
                          </h5>
                          <p className="card-text">
                            Get flat ₹.30 cashback on orders above ₹.99 for 10
                            orders. No code required.
                          </p>
                          <p className="card-text">
                            <small className="text-info"><Button>Reddem Now</Button></small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card offer-card-horizontal border-0 shadow-sm">
                    <div className="row d-flex align-items-center no-gutters">
                      <div className="col-md-4 col-4 p-4">
                        <img
                          src="image/deals/1.jpg"
                          className="card-img"
                          alt="..."
                        />
                      </div>
                      <div className="col-md-8 col-8">
                        <div className="card-body">
                          <h5 className="card-title">
                            Get flat ₹.30 cashback using Amazon Pay
                          </h5>
                          <p className="card-text">
                            Get flat ₹.30 cashback on orders above ₹.99 for 10
                            orders. No code required.
                          </p>
                          <p className="card-text">
                            <small className="text-info"><Button>Reddem Now</Button></small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card offer-card-horizontal border-0 shadow-sm">
                    <div className="row d-flex align-items-center no-gutters">
                      <div className="col-md-4 col-4 p-4">
                        <img
                          src="image/deals/1.jpg"
                          className="card-img"
                          alt="..."
                        />
                      </div>
                      <div className="col-md-8 col-8">
                        <div className="card-body">
                          <h5 className="card-title">
                            Get flat ₹.30 cashback using Amazon Pay
                          </h5>
                          <p className="card-text">
                            Get flat ₹.30 cashback on orders above ₹.99 for 10
                            orders. No code required.
                          </p>
                          <p className="card-text">
                            <small className="text-info"><Button>Reddem Now</Button></small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card offer-card-horizontal border-0 shadow-sm">
                    <div className="row d-flex align-items-center no-gutters">
                      <div className="col-md-4 col-4 p-4">
                        <img
                          src="image/deals/1.jpg"
                          className="card-img"
                          alt="..."
                        />
                      </div>
                      <div className="col-md-8 col-8">
                        <div className="card-body">
                          <h5 className="card-title">
                            Get flat ₹.30 cashback using Amazon Pay
                          </h5>
                          <p className="card-text">
                            Get flat ₹.30 cashback on orders above ₹.99 for 10
                            orders. No code required.
                          </p>
                          <p className="card-text">
                            <small className="text-info"><Button>Reddem Now</Button></small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Deals;
