import React, { useState } from "react";
import './Banner.css'
const Banner = (props) => {
  console.log('banner component')
//   start usestate hooks  
  const title = props.title
  const subTitle = props.subtitle
//   end usestate hooks   
  return (
    <>
      <section className="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
        <h1 className="text-white">{title}</h1>
        <h6 className="text-white-50">{subTitle}</h6>
      </section>
    </>
  );
};
export default Banner
