import React, { useEffect, useState } from "react";
import { Card, CardActions } from "@mui/material";
import './Category.css';
import { Link } from "react-router-dom";
import { getData } from "../../services/category";
import url,{img_path, slugify} from "../../config";
const Category = () => {
   const [data, setData] = useState(null)
    useEffect(()=>{
        getData.then((res)=>{
           console.log(res)
            setData(res.data.result)
        })
    },[])
    return (
        <>
          <div className="container my-4" >
             <h3 className="pb-3 heading">Categories</h3>
             <div className="row ">
              {data && data.map((item)=>
                (<div className="col-6 col-lg-3 mb-4">
                 <Link to={`/category/${slugify(item.cat_name)}`} key={item.id} state={{id: item.id}}>
                 <Card className="shadow">
                   <img src={`${img_path}/category/${item.image}`} className="img-fluid"/>
                   <CardActions className="text-center">
                      <h5 className="mx-auto"><small>{item.cat_name}</small></h5>
                   </CardActions>
                 </Card>
                 </Link>
                </div>)
              )}               
             <div className="col-6 col-lg-3 mb-4">
               <Link to="/menu">
                <Card className="shadow">
                <img src="image/category/all.jpg" className="img-fluid"/>
                  <CardActions className="text-center">
                     <h5 className="mx-auto"><small>VIEW ALL MENU</small></h5>
                  </CardActions>
                </Card>
                </Link>
             </div>
             </div>
          </div>
        </>
    )
}
export default Category