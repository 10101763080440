import React from "react";

const Careers = () => {
  return (
    <>
      <section className="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
        <h1 className="text-white">Careers</h1>
        <h6 className="text-white-50">
          Explore top deals and offers exclusively for you!
        </h6>
      </section>
      <section className="section pt-5 pb-5">
        <div className="container">
          <div className="col-md-12">
            <h4 className="font-weight-bold mt-0 mb-3">Careers</h4>

            <p>
              It all started with Colonel Harland Sanders creating a finger
              lickin’ good recipe more than 75 years ago. A list of secret herbs
              and spices scratched out on the back of his kitchen door, that
              continues to be followed across 145 countries, with more than
              800,000 team members breading and freshly preparing bucket after
              bucket of our signature Hot &amp; Crispy chicken. The Colonel’s
              Secret Recipe continues to be as guarded; and our commitment
              towards Food, People and Planet remains as steadfast. With a new
              KFC restaurant opening every 6 hours, we power on to serve the
              World’s Tastiest Chicken!
            </p>
            
            <div
              className="card offer-card-horizontal border-0 shadow-sm col-md-12 my-4">
              <div className="row d-flex align-items-center no-gutters">
                <div className="">
                  <h3 className="login-heading mb-4">Connect Us</h3>
                  <form>
                    <div className="form-label-group">
                      <input
                        type="name"
                        id="inputName"
                        className="form-control"
                        placeholder="Enter Your Name"
                      />
                      <label for="inputName">Enter Your Name</label>
                    </div>
                    <div className="form-label-group">
                      <input
                        type="phone"
                        id="inputPhone"
                        className="form-control"
                        placeholder="Enter Your Phone"
                      />
                      <label for="inputPhone">Enter Your Phone</label>
                    </div>
                    <div className="form-label-group">
                      <input
                        type="email"
                        id="inputEmail"
                        className="form-control"
                        placeholder="Enter Your Email"
                      />
                      <label for="inputEmail">Enter Your Email</label>
                    </div>

                    <a
                      href="#"
                      className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                    >
                      Submit
                    </a>
                  </form>
                  <hr className="my-4" />
                </div>
              </div>
            </div>
            <p>
              It all started with Colonel Harland Sanders creating a finger
              lickin’ good recipe more than 75 years ago. A list of secret herbs
              and spices scratched out on the back of his kitchen door, that
              continues to be followed across 145 countries, with more than
              800,000 team members breading and freshly preparing bucket after
              bucket of our signature Hot &amp; Crispy chicken. The Colonel’s
              Secret Recipe continues to be as guarded; and our commitment
              towards Food, People and Planet remains as steadfast. With a new
              KFC restaurant opening every 6 hours, we power on to serve the
              World’s Tastiest Chicken!
            </p>
            <p>
              It all started with Colonel Harland Sanders creating a finger
              lickin’ good recipe more than 75 years ago. A list of secret herbs
              and spices scratched out on the back of his kitchen door, that
              continues to be followed across 145 countries, with more than
              800,000 team members breading and freshly preparing bucket after
              bucket of our signature Hot &amp; Crispy chicken. The Colonel’s
              Secret Recipe continues to be as guarded; and our commitment
              towards Food, People and Planet remains as steadfast. With a new
              KFC restaurant opening every 6 hours, we power on to serve the
              World’s Tastiest Chicken!
            </p> 
          </div>
        </div>
      </section>
    </>
  );
};
export default Careers;
