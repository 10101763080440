import React,{useState} from "react";
import "./Footer.css";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from "react-router-dom";
import { getData } from "../../services/category";
import { slugify } from "../../config";
import { capitalize } from "@mui/material";
const Footer = () => {
   const [data, setData]=  useState(null)
  getData.then((res)=>{
   setData(res.data.result)
  })
  const toTitleCase = (str) => {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
  }
  return (
    <>
      <section className="section pt-5 pb-5 text-center bg-light">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="m-0">
                Operate food store or restaurants?{" "}
                <Link to='/contact'>Contact With Us</Link>
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="footer pt-5 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 col-sm-12">
            <div className="app">
                <a href="#">
                  <img className="img-fluid" src="/image/footer/logo.png" width="150px"/>
                </a>
              </div>
              <h6 className="mb-3 mt-4">Subscribe to our Newsletter</h6>
              <form className="newsletter-form mb-1">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Please enter your email"
                    className="form-control"
                  />
                  <div className="input-group-append">
                    <button type="button" className="btn btn-primary">
                      Subscribe
                    </button>
                  </div>
                </div>
              </form>
              <p>
                <a className="text-info" href="register.html">
                  Register now
                </a>{" "}
                to get updates on <a href="offers.html">Offers and Coupons</a>
              </p>
              
            </div>
            <div className="col-md-1 col-sm-6 mobile-none"></div>
            <div className="col-md-2 col-6 col-sm-4">
              <h6 className="mb-3">About KC</h6>
              <ul>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                <Link to="/contact">Reviews</Link>
                </li>
                <li>
                <Link to="/blogs">Blog</Link>
                </li>
                <li>
                <Link to="/career">Careers</Link>
                </li>
                <li>
                <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-6 col-sm-4">
              <h6 className="mb-3">Menu</h6>
              <ul>
                {data && data.map((item)=>
                   (<li>
                   <Link to={`/category/${slugify(item.cat_name)}`} state={{id: item.id}}>{ toTitleCase(item.cat_name) }</Link>
                   </li>)
                )}
              </ul>
            </div>
            <div className="col-md-2 m-none col-4 col-sm-4">
              <h6 className="mb-3">Learn More</h6>
              <ul>
                <li>
                  <a href="#">Privacy</a>
                </li>
                <li>
                  <a href="#">Security</a>
                </li>
                <li>
                  <a href="#">Terms</a>
                </li>
                <li>
                  <a href="#">Sitemap</a>
                </li>
                <li>
                  <a href="#">Guidelines</a>
                </li>
              </ul>
              <div className="mt-4">
                 <a href="#" className="text-muted px-2"><FacebookOutlinedIcon/></a>
                 <a href="#" className="text-muted px-2"><InstagramIcon/></a>
                 <a href="#" className="text-muted px-2"><TwitterIcon/></a>
                 <a href="#" className="text-muted px-2"><YouTubeIcon/></a>
                 
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="pt-4 pb-4 text-center">
        <div className="container">
          <p className="mt-0 mb-0">
            © Copyright 2022 CripcyChicken.co. All Rights Reserved
          </p>
          <small className="mt-0 mb-0">
            {" "}
            Made with <i className="fas fa-heart heart-icon text-danger"></i> by
            <a className="text-danger" target="_blank" href="#">
              Thetechnovate
            </a>
          </small>
        </div>
      </footer>
    </>
  );
};
export default Footer;
