import React, {useEffect, useState} from "react";
import './Product.css'
import { Button } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { getData } from "../../services/product";
import { img_path } from "../../config";
import { useCart } from "react-use-cart";
const Product = () => {
  const [data, setData] = useState(null)
  const [showData, setShowData] = useState(null)
  const [id, setId] = useState(null)
  const location = useLocation()
  const cart = useCart()
  const params = useParams()
  useEffect(()=>{
    
  })
 useEffect(()=>{
  getData.then((res)=>{
    setData(res.data.data)
    if(location.state != null){
      console.log('true',location.state)
      const cat_id = location.state.id
      const pure_value =  res.data.data.filter((items)=> items.category_id == cat_id);
      setShowData(pure_value) 
    }else{
      setShowData(res.data.data)
    } 
  })
 },[location])
  const handleRemove = (id) => {
      cart.removeItem(id)
  }
  return (
    <>
    {showData && showData.map((item)=>    
      (
      <div className="col-6 col-md-4 mb-4" key={item.id}>
      <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
        <div className="list-card-image">
          <a>
            <img src={`${img_path}/product/${item.image}`} className="img-fluid item-img"/>
          </a>
        </div>
        <div className="p-3 position-relative">
          <div className="list-card-body">
            <h6 className="mb-1">
              <a className="text-black">
                 {item.product_name}
              </a>
            </h6>
            <p className="text-gray mb-3">{item.description} • {item.product_type}</p>
            <p className="text-gray mb-3 time">
              <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
                <i className="icofont-wall-clock"></i>
              </span>{" "}
              <h5 className="float-right text-dark">₹ {item.sale_price}</h5>
            </p>
          </div>
          {/* <div className="list-card-badge">
            <span className="badge badge-danger">OFFER</span>{" "}
            <small>65% off | Use Coupon OSAHAN50</small>
          </div> */}
          <div>{cart.inCart(item.id) ? (<Button className="mt-2" size="small" color="error" onClick={() => handleRemove(item.id)} variant="contained">Remove Item</Button>)  : (<Button variant="outlined" size="small" className="mt-2" onClick={() => cart.addItem(item)}>Add to cart</Button>)}</div>
        </div>
      </div>
      </div>)
      )}
    </>
  )
};
export default Product
