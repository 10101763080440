import React from "react";
import Banner from "../../Banner/Banner";
import Offer from "../../Offer/Offer";
const Deal = () => {
    console.log('deal component')
    const title = "Offers for you"
    const subTitle = "Explore top deals and offers exclusively for you!"
    return (
        <>
          <Banner title={title} subtitle={subTitle}/>
          <div className="container py-4">
              <div className="row">
                  <div className="col-md-4">
                        <Offer/>
                  </div>
                  <div className="col-md-4">
                       <Offer/>
                  </div>
                  <div className="col-md-4">
                      <Offer/>
                  </div>
              </div>
          </div>
        </>
    )
}
export default Deal;