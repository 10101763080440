import React from "react";
import Banner from "../../Banner/Banner";
import Sidebar from "../../Sidebar/Sidebar";
import Product from "../../Product/Product";
const ProductCategory = () => {
    console.log('product category component')
    const title = "Category"
    const subTitle = "Best deals at your favourite restaurants"
    return (
        <>
          <Banner title={title} subtitle={subTitle}/>
          <div className="container py-4">
              <div className="row">
                  <div className=" col-sm-12 col-md-3">
                      <Sidebar/>
                  </div>
                  <div className="col-md-9">
                      <div className="row">
                           <Product/>
                      </div>
                  </div>
              </div>
          </div>
        </>
    )
}
export default ProductCategory