import React, {useState} from "react";
import './Slider.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const Slider = () => {
    
    return (
        <>
          <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      style={{
        "--swiper-pagination-color": "#fff",
        "--swiper-navigation-color": "#fff",
      }}      
    >
      <SwiperSlide><img src="image/slider/13.jpg" className="swiper-img"/></SwiperSlide>
      <SwiperSlide><img src="image/slider/121.jpg" className="swiper-img"/></SwiperSlide>      
    </Swiper>
        </>
    )
}
export default Slider;