//const url = "http://162.241.116.186/kripsy-chicken-api/public/api"
const url = "https://api.krispychicken.co/public/api"
//const img_path = "http://162.241.116.186/kripsy-chicken-api/public/images"
const img_path = "https://api.krispychicken.co/public/images"
const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
export default url;
export {img_path, slugify}