import React from "react";

const Blog = () => {
  return (
    <>
      <section className="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
        <h1 className="text-white">Blogs</h1>
        <h6 className="text-white-50">
          Explore top deals and offers exclusively for you!
        </h6>
      </section>
      <section className="section pt-5 pb-5">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-4">
              <div className="card offer-card-horizontal border-0 shadow-sm">
                <div className="row d-flex align-items-center no-gutters">
                  <img src="img/pro1.jpg" className="card-img" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">BLog 1</h5>
                    <p className="card-text">
                      Get flat $.30 cashback on orders above $.99 for 10 orders.
                      No code required.
                    </p>
                    <p className="card-text">
                      <small className="text-info">
                        <a href="#">Read More</a>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card offer-card-horizontal border-0 shadow-sm">
                <div className="row d-flex align-items-center no-gutters">
                  <img src="img/pro2.jpg" className="card-img" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">Blog 2</h5>
                    <p className="card-text">
                      Get flat $.30 cashback on orders above $.99 for 10 orders.
                      No code required.
                    </p>
                    <p className="card-text">
                      <small className="text-info">
                        <a href="#">Read More</a>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card offer-card-horizontal border-0 shadow-sm">
                <div className="row d-flex align-items-center no-gutters">
                  <img src="img/pro3.jpg" className="card-img" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">Blog 3</h5>
                    <p className="card-text">
                      Get flat $.30 cashback on orders above $.99 for 10 orders.
                      No code required.
                    </p>
                    <p className="card-text">
                      <small className="text-info">
                        <a href="#">Read More</a>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Blog;
