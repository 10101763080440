import React from "react";
import "./Offer.css";
const Offer = () => {
  return (
    <>
      <div class="card offer-card border-0 shadow-sm">
        <div class="card-body">
          <h5 class="card-title">
            <img src="image/bank/1.png" /> GURDEEP50
          </h5>
          <h6 class="card-subtitle mb-2 text-block">
            Get 50% OFF on your first osahan eat order
          </h6>
          <p class="card-text">
            Use code GURDEEP50 & get 50% off on your first osahan order on
            Website and Mobile site. Maximum discount: $600
          </p>
          <a href="#" class="card-link">
            REDDEM
          </a>
          <a href="#" class="card-link">
            KNOW MORE
          </a>
        </div>
      </div>
    </>
  )
};
export default Offer

