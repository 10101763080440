import React,{useState} from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField,Button, Paper, accordionActionsClasses} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import { useCart } from 'react-use-cart';
const Checkout = () => {
   const cartItem = useCart()
   const styles = {
    float_l: {
        float: 'left!impotant'
    }
}
const toLowerCase=  (name) => {
    if(name !== null){
       return String(name).toLowerCase(); 
    }else{
        return ''
    }
}
const gst = (price) => {
    return price * (8/100)
}
const [accor, setAccord] = useState({one: true, two: true});
    return (
        <>
          <div className='container my-4'>
            <h2 style={{textAlign: 'left'}}>Checkout</h2>
            <h5 className='mb-4'><LockIcon/> Secure Checkout</h5>
            <div className='row'>
            <div className='col-8'>
                <Accordion expanded={accor.one}>
                    <AccordionSummary onClick={() => setAccord({one: !accor.one})} expandIcon={<ExpandMoreIcon/>} style={{backgroundColor: '#f8f7f5'}} aria-controls="panel1a-content" id="panel1a-header">
                        <h5>Delivery Address</h5>
                    </AccordionSummary>
                    <AccordionDetails className='py-4'>
                          <TextField name='dist' placeholder='District' variant="filled"/>                      
                          <TextField name='city' placeholder='City' variant="filled" style={{marginLeft: '8px'}}/>
                          <TextField name='address' placeholder='Address' variant="filled" style={{marginLeft: '8px'}}/>
                          <TextField name='plot' className='mt-2' placeholder='Plot No' variant="filled" style={{marginLeft: '8px'}}/> 
                          <TextField name='pin' className='mt-2' placeholder='Pincode' variant="filled" style={{marginLeft: '8px'}}/>
                          {/* <Button variant="contained" className='mt-3'><AddCircleOutlineIcon/> Add</Button> */}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={accor.two}>
                    <AccordionSummary onClick={() => setAccord({two: !accor.two})} expandIcon={<ExpandMoreIcon/>} style={{backgroundColor: '#f8f7f5'}} aria-controls="panel2a-content"
          id="panel2a-header">
                        <h5> Contact Info</h5>
                    </AccordionSummary>
                    <AccordionDetails className='py-4'>  
                          <TextField name='name' placeholder='Full Name' variant="filled"/>                    
                          <TextField name='city' placeholder='Phone Number' variant="filled" style={{marginLeft: '8px'}}/>
                          <TextField name='plot' placeholder='Email' variant="filled" style={{marginLeft: '8px'}}/> 
                          {/* <Button variant='contained' className='mt-3 ml-3'><AddCircleOutlineIcon/> Add</Button> */}
                    </AccordionDetails>
                </Accordion>
            </div> 
            <div className='col-4'>
                <Paper variant='elevation' elevation={8} className="p-4">
                    <h4 className='mb-3 text-danger'>{cartItem.totalItems} Items</h4>
                    <table className='w-100 px-2' style={{borderBottom: '1px solid #e9e5e5'}}>
                          <thead></thead>
                         <tbody>
                          <tr>
                            <td style={styles.float_l}>SubTotal</td>
                            <td>Rs {parseFloat(cartItem.cartTotal).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td style={styles.float_l}>GST</td>
                            <td>Rs {parseFloat(gst(cartItem.cartTotal)).toFixed(2)}</td>
                          </tr>
                          </tbody>
                          <tfoot>

                          </tfoot>
                       </table>
                    <Button variant="contained" className='mt-3'>Continue to Payment ₹{parseFloat(cartItem.cartTotal + gst(cartItem.cartTotal)).toFixed(2)}</Button>
                </Paper>
            </div>
            </div>
          </div>  
        </>
    );
}

export default Checkout;
