import React, {useState, useEffect} from "react";
import "./Sidebar.css";
import { getData } from "../../services/category";
import { Link } from "react-router-dom";
import { slugify } from "../../config";
const Sidebar = () => {
  const [data, setData] = useState(null)
  useEffect(()=>{
       getData.then((res)=>{
           setData(res.data.result)
       })
  },[])  
  return (
    <>
      <div className="filters shadow-sm rounded bg-white">
        <div className="filters-header border-bottom pl-4 pr-4 pt-3 pb-3">
          <h5 className="m-0">Filter By</h5>
        </div>
        <div className="filters-body">
          <div id="accordion">
            <div className="filters-card border-bottom p-4">
              <div className="filters-card-header" id="headingOne">
                <h6 className="mb-0">
                  <a
                    href="#"
                    className="btn-link"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Category <i className="icofont-arrow-down float-right"></i>
                  </a>
                </h6>
              </div>
              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="filters-card-body card-shop-filters">
                  {data && data.map((item)=>  
                    <Link to={`/category/${slugify(item.cat_name)}`} key={item.id} state={{id: item.id}}>                
                    <div className="custom-control custom-checkbox py-2">
                      <label className="custom-control-label" for="cb1">
                     {item.cat_name}<small className="text-black-50"></small>
                      </label>
                    </div>
                    </Link>
                  )}
                  <div className="mt-2">
                    <Link to={`/all-items`}>
                      See all
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
