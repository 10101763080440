import React from "react";

const Contact = () => {
  return (
    <>
      <section className="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
        <h1 className="text-white">Contact Us</h1>
        <h6 className="text-white-50">
          Explore top deals and offers exclusively for you!
        </h6>
      </section>
      <section className="section pt-5 pb-5">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-6">
              <div className="card offer-card-horizontal border-0 shadow-sm">
                <div className="row d-flex align-items-center no-gutters">
                  <div className="col-md-9 col-lg-8 mx-auto pl-5 pr-5">
                    <h3 className="login-heading mb-4">Connect with Us</h3>
                    <form>
                      <div className="form-label-group">
                        <input
                          type="name"
                          id="inputName"
                          className="form-control"
                          placeholder="Enter Your Name"
                        />
                        <label for="inputName">Enter Your Name</label>
                      </div>
                      <div className="form-label-group">
                        <input
                          type="phone"
                          id="inputPhone"
                          className="form-control"
                          placeholder="Enter Your Phone"
                        />
                        <label for="inputPhone">Enter Your Phone</label>
                      </div>
                      <div className="form-label-group">
                        <input
                          type="email"
                          id="inputEmail"
                          className="form-control"
                          placeholder="Enter Your Email"
                        />
                        <label for="inputEmail">Enter Your Email</label>
                      </div>

                      <a
                        href="#"
                        className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                      >
                        Submit
                      </a>
                    </form>
                    <hr className="my-4" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card offer-card-horizontal border-0 shadow-sm">
                <div className="row d-flex align-items-center no-gutters">
                  <div className="gold-members p-4 col-md-12">
                    <div className="media">
                      <div className="mr-3">
                        <i className="icofont-ui-home icofont-3x"></i>
                      </div>
                      <div className="media-body">
                        <h6 className="mb-1 text-black">Home</h6>
                        <p className="text-black">
                          291/d/1, 291, Jawaddi Kalan, Ludhiana, Punjab 141002,
                          India
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="gold-members p-4 col-md-6">
                    <div className="media">
                      <div className="mr-3">
                        <i className="icofont-ui-email icofont-3x"></i>
                      </div>
                      <div className="media-body">
                        <h6 className="mb-1 text-black">Email</h6>
                        <p className="text-black">krispychicken@gmail.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="gold-members p-4 col-md-6">
                    <div className="media">
                      <div className="mr-3">
                        <i className="icofont-ui-call icofont-3x"></i>
                      </div>
                      <div className="media-body">
                        <h6 className="mb-1 text-black">Phone</h6>
                        <p className="text-black">+91 9999999999</p>
                      </div>
                    </div>
                  </div>
                  <div className="map col-md-12 pl-2 pr-2">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119693.9688753601!2d85.77693003297885!3d20.364883715189052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190a0edb334f85%3A0x1cdf39e930f3e4b9!2sKrispy%20Chicken!5e0!3m2!1sen!2sin!4v1670840288910!5m2!1sen!2sin"
                      width="100%"
                      height="150"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
